import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Scope extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">SCOPE AND LIST OF TOPICS</h4>
            <div className="par">
            Artificial Intelligence (AI) is the science & engineering of making intelligent machines and systems. AI is a multi-disciplinary field which has become an increasingly important part of humanity, shaping how we interact with the world. AI technologies are integrated into numerous aspects of daily life, from personalized recommendations on streaming platforms to virtual assistants, smart home devices, and medical diagnostics. In many ways, AI enhances human productivity, offering innovative solutions to complex problems. AI has emerged as the most transformative technology of the 21st century, reshaping every sector. From medicine and education to finance, smart homes, transportation, and entertainment, AI is revolutionizing the way we live and interact with technology. By enabling machines to simulate human intelligence through learning and reasoning, AI has unlocked unprecedented potential for solving complex problems, automating repetitive tasks, and improving overall efficiency. With advancements in machine learning, natural language processing, computer vision, and robotics, AI continues to push the boundaries of what is possible, paving the way for a smarter and more interconnected world.
            <br/><br/>
            The upcoming international conference provides a platform for researchers, practitioners, and industry leaders to come together and explore the latest developments, challenges, and opportunities in AI. Topics will range from foundational research in algorithms and models to real-world applications and ethical considerations. As AI evolves at an accelerating pace, the event will foster collaboration and discussions on key issues such as transparency, fairness, and the societal impacts of AI technologies. Join us at this exciting gathering to share knowledge, showcase innovations, and collectively shape the future of Artificial Intelligence.
            <br/><br/>
            The list of topics below is by no means meant to be exhaustive.
            <br/> <br/>
            <div>
                <Icon icon='link' size="1x"/> <Link to="/air-res2025/topics/research">RESEARCH - ARTIFICIAL INTELLIGENCE</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/air-res2025/topics/ethics">ETHICS RESEARCH - ETHICAL CONCERNS IN ARTIFICIAL INTELLIGENCE</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/air-res2025/topics/society">SOCIETY - SOCIETAL AND PHILOSOPHICAL IMPACTS OF ARTIFICIAL INTELLIGENCE</Link><br/>
                <Icon icon='link' size="1x"/> <Link to="/air-res2025/topics/data">DATA SCIENCE IN THE CONTEXT of ARTIFICIAL INTELLIGENCE</Link><br/>
              </div>
              <br /><br />
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Scope;