import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PapC extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Paper Categories</h4>
            <div className="par">
            <b><i>Please note that in various announcements</i></b> <br />
            The names/terms "FULL/REGULAR RESEARCH PAPERS" and "LATE BREAKING PAPERS" are used interchangeably; i.e., they are the same category. <br />
The names/terms "SHORT RESEARCH PAPERS" and "CONCISE RESEARCH PAPERS" are used interchangeably; i.e., they are the same category. <br />
The names/terms "POSTER/EXTENDED ABSTRACT PAPERS" and "POSITION RESEARCH PAPERS" are used interchangeably; i.e., they are the same category.
<br />
            
            <hr />
            
            Please note that the number of pages stated below includes all figures, tables, and references. <br /><br />

            <b>- FULL/REGULAR RESEARCH PAPERS:</b> <br />
            Page Limitation:	<br />
            Between 12 and 15 pages using one-column Springer Nature's formatting. <br />
            OR:	<br />
            Between 6 and 8 pages using two-column formatting.

            <br /><br />
            Regular Research Papers should provide detail original research contributions. They must report new research results that represent a contribution to the field; sufficient details and support for the results and conclusions should also be provided. The work presented in regular papers are expected to be at a stage of maturity that with some additional work can be published as journal papers. Presentation Mode Options: <br />
            <a href="https://www.american-cse.org/air-res2025/presentation_modes" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/presentation_modes</a>

            <br />
            <br />
            <b>- SHORT RESEARCH PAPERS:</b> <br />
            Page Limitation:	<br />
            Between 6 and 11 pages using one-column Springer Nature's formatting. <br />
            OR:	<br />
            Between 4 and 5 pages using two-column formatting.

            <br /><br />
            Short Research Papers report on ongoing research projects. They should provide overall research methodologies with some results. The work presented in short research papers are expected to be at a stage of maturity that with some additional work can be published as regular research papers. Presentation Mode Options: <br />
          <a href="https://www.american-cse.org/air-res2025/presentation_modes" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/presentation_modes</a>


          <br />
          <br />
          <b>- POSITION/POSTER/EXTENDED-ABSTRACT RESEARCH PAPERS:</b> <br />
            Page Limitation:	<br />
            Between 2 and 5 pages using one-column Springer Nature's formatting. <br />
            OR:	<br />
            Between 2 and 3 pages using two-column formatting.

          <br /><br />
          Poster papers report on ongoing research projects that are still in their infancy (i.e., at very early stages). Such papers tend to provide research methodologies without yet concrete results. Presentation Mode Options: 
<br />
      <a href="https://www.american-cse.org/air-res2025/presentation_modes" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/presentation_modes</a>

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PapC;