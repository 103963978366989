import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Org extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Conference Organization</h4>
            {/* <div className="par">
            </div> */}


            <h6 className="par_header">STEERING COMMITTEE CO-CHAIRS</h6>
            <div className="par">
              <div>
              <b>Dr. Soheyla Amirian</b> - Seidenberg School of CSIS, Computer Science, Pace University, New York, USA
              <br/> <br/>
              <b>Professor Emeritus Hamid R. Arabnia</b> - Editor-in-Chief, The Journal of Supercomputing (Springer); Fellow & Adviser of Center of Excellence in Terrorism, Resilience, Intelligence & Organized Crime Research (CENTRIC); Professor Emeritus of Computer Science, School of Computing, The University of Georgia, Georgia, USA
              <br/> <br/>
              <b>Professor Leonidas Deligiannidis</b> - Professor, Department of Computer Science and Computer Networking, Wentworth Institute of Technology, Massachusetts, USA
              <br/> <br/>
              <b>Dr. Farid Ghareh Mohammadi</b> - in transition (formerly at Mayo Clinic, USA)
              <br/> <br/>
              <b>Dr. Farzan Shenavarmasouleh</b> - Medialab Inc, Georgia, USA
              <br/> <br/>
              <b>Professor Fernando G. Tinetti</b> - Facultad de Informatica, Universidad Nacional de La Plata, La Plata, Argentina; Researcher CIC Prov. Bs. As., Argentina; Recipient of the NSF-TCPP Early Adopter Award, NSF/IEEE-TCPP Curriculum Initiative on Parallel and Distributed Computing
              <br/> <br/>
              <b>Professor Quoc-Nam Tran</b> - Department of Computer Science, Southeastern Louisiana University, Louisiana, USA
              
              </div>
            </div>

              <br/>

              <h6 className="par_header">MEMBERS OF STEERING COMMITTEE</h6>
            <div className="par">
              <div>
                <b>Professor Babak Akhgar </b> - Fellow of the British Computer Society, CITP, Professor of Informatics, Co-Director of CENTRIC (Centre of Excellence in Terrorism, Resilience, Intelligence & organised Crime research), Sheffield Hallam University, Sheffield, United Kingdom
                <br/> <br/>
                <b>Professor Abbas M. Al-Bakry </b> - University President, University of IT & Communications, Baghdad, Iraq
                <br/> <br/>
                <b>Professor Emeritus Nizar Al-Holou </b> - Electrical and Computer Engineering; Vice Chair, IEEE/SEM-Computer Chapter, University of Detroit Mercy, Michigan, USA
                <br/> <br/>
                <b>Professor Rajab Challoo </b> - Professor and Chairman, EECS Department; Director, Center for Controls, Robotics, Intelligent Systems and Sensors Research, CRISS; President, Faculty Senate; Former Chair of Computer Society (for 7 years); Texas A&M University-Kingsville, Kingsville, Texas, USA
                <br/> <br/>
                <b>Professor Chien-Fu Cheng </b> - Professor of CS, Department of Computer Science and Information Engineering, Graduate Institute of Networking & Communication, Tamkang University, Taiwan, R.O.C.
                <br/> <br/>
                <b>Professor Hyunseung Choo </b> - Director of Intelligent HCI Convergence Research Center (ITRC) of Ministry of Information and Communication; International Advisory Chair, ACM RACS; College of Information and Communication Engineering, Professor of IS, Department of Interaction Science, Sungkyunkwan University (SKKU), Suwon, Republic of Korea
                <br/> <br/>
                <b>Professor Emeritus Kevin Daimi </b> - Computer Science and Software Engineering Programs, Department of Mathematics, Computer Science and Software Engineering, University of Detroit Mercy, Detroit, Michigan, USA
                <br/> <br/>
                <b>Professor Eman M. El-Sheikh </b> - Director, Center for Cybersecurity; Member of AWIS (Association for Women in Science); Professor of CS, Department of Computer Science, University of West Florida, Pensacola, Florida, USA
                <br/> <br/>
                <b>Professor Mary Mehrnoosh Eshaghian-Wilner </b> – Adjunct Professor of Electrical Engineering, University of California, Los Angeles, California, USA
                <br/> <br/>
                <b>Dr. David L. Foster </b> - Associate Professor, Computer Engineering; Kettering University, Michigan, USA
                <br/> <br/>
                <b>Professor Ching-Hsien (Robert) Hsu </b> - Department of Computer Science and Information Engineering, Chung Hua University, Hsinchu, Taiwan, R.O.C.; and Distinguished Chair Professor, Tianjin University of Technology, P. R. China
                <br/> <br/>
                <b>Professor James J. (Jong Hyuk) Park </b> - Department of Computer Science and Engineering (DCSE), SeoulTech, Republic of Korea; President, FTRA, EiC, HCIS Springer, JoC, IJITCC; Head of DCSE, SeoulTech, Republic of Korea
                <br/> <br/>
                <b>Professor Mohammad S. Obaidat </b> - Fellow of IEEE; Fellow of SCS; Past President of Society for Modeling and Simulation International (SCS); Editor-in-Chief of International Journal of Communication Systems (Wiley); Editor, IEEE Wireless Communications; Professor, Monmouth University, New Jersey, USA
                <br/> <br/>
                <b>Dr. Gerald Schaefer </b> - Senior Lecturer, Computer Science; Leader of the Vision, Autonomous and Human-Computer Systems Research Group (VAHC); Loughborough University, United Kingdom
                <br/> <br/>
                <b>Professor Shiuh-Jeng Wang </b> - Department of Information Management; Director, Information Cryptology & Construction Lab. (ICCL); Vice-President / Deputy President, Chinese Cryptology and Information Security Association (CCISA), R.O.C.; Chair, Forensic Research & Development task force group (ICCL-FROG); Professor, Central Police University, Taiwan, R.O.C.
                <br/> <br/>
                <b>Professor Layne T. Watson </b> - Fellow of IEEE; Fellow of The National Institute of Aerospace; Professor of Computer Science, Mathematics, and Aerospace and Ocean Engineering, Virginia Polytechnic Institute & State University, Blacksburg, Virginia, USA
                <br/> <br/>
                <b>Professor Chao-Tung Yang </b> - Director, Computer Center; Executive Secretary, Advisory Committee for Research and Lab. Equipment; High-Performance Computing Laboratory; Professor, Department of Computer Science, Tunghai University, Taichung City, Taiwan R.O.C.
                <br/> <br/>
                <b>Professor Mary Yang </b> - Director of MidSouth Bioinformatics Center and Director of Joint Bioinformatics Ph.D. Program, University of Arkansas George Washington Donaghey College of Engineering & IT and University of Arkansas for Medical Sciences, Little Rock, Arkansas, USA
                </div>
              </div>

              <br/>

              <h6 className="par_header">MEMBERS OF INTERNATIONAL PROGRAM COMMITTEES</h6>
            <div className="par">

                <div>
                <b>Professor Afrand Agah  </b> -  Graduate Coordinator, Department of Computer Science, West Chester University of Pennsylvania, Pennsylvania, USA
                <br/> <br/>
                <b>Bharat Bhushan Agarwal  </b> -  Reader, IFTM University, Moradabad, India
                <br/> <br/>
                <b>Dr. Omaima Nazar Ahmad AL </b> - Allaf  -  CIS Deptartment, Faculty of Sciences and Information Technology, Al - Zaytoonah University of Jordan, Amman, Jordan
                <br/> <br/>
                <b>Professor Wasim A. Al </b> - Hamdani  -  Professor of Cryptography and InfoSec; Information Security Lab.; Computer Science; Kentucky State University, Frankfort, Kentucky, USA
                <br/> <br/>
                <b>Dr. Ismail Khalil Al Ani  </b> -  Dean, College of Engineering and Computer Science, Ittihad University, Ras Al Khaimah, United Arab Emirates (UAE)
                <br/> <br/>
                <b>Professor Mehran Asadi  </b> -  Information Technology; Department of Business and Entrepreneurial Studies, Lincoln University, Pennsylvania, USA
                <br/> <br/>
                <b>Dr. Dr. Travis Atkison  </b> -  Assistant Professor, Computer Science, The University of Alabama, Tuscaloosa, Alabama, USA
                <br/> <br/>
                <b>Dr. Azita Bahrami  </b> -  President, IT Consult, Georgia, USA
                <br/> <br/>
                <b>Dr. Mehdi Bahrami  </b> -  IEEE Senior Member; Fujitsu Laboratories of America, Inc., California, USA
                <br/> <br/>
                <b>Dr. P. Balasubramanian  </b> -  Senior Member, IEEE, School of Computer Science and Engineering, Nanyang Technological University, Singapore
                <br/> <br/>
                <b>Dr. Petra Saskia Bayerl  </b> -  Department of Organisation and Personnel Management, Rotterdam School of Management (RSM), Erasmus University Rotterdam, Holland, The Netherlands
                <br/> <br/>
                <b>Professor Jane M. Binner  </b> -  Chair in Finance, Department of Accounting and Finance, Birmingham Business School, University of Birmingham, Birmingham, West Midlands, United Kingdom
                <br/> <br/>
                <b>Professor Dr. Juan </b> - Vicente Capella - Hernandez  -  Universitat Politecnica de Valencia (UPV), Department of Computer Engineering (DISCA), Valencia, Spain
                <br/> <br/>
                <b>Professor Juan Jose Martinez Castillo  </b> -  Director, The Acantelys Alan Turing Nikola Tesla Research Group and GIPEB, Universidad Nacional Abierta, Venezuela
                <br/> <br/>
                <b>Dr. Rui Chang  </b> -  Lab Director; Department of Genetics and Genomic Science, Icahn Institute for Genomics and Multiscale Biology, Mount Sinai School of Medicine, New York, USA
                <br/> <br/>
                <b>Professor Dongsheng Che  </b> -  Director, Che Lab of Bioinformatics at ESU, Department of Computer Science, East Stroudsburg University of Pennsylvania, Pennsylvania, USA
                <br/> <br/>
                <b>Dr. Jianhung Chen  </b> -  Department of Computer Science and Information Engineering, Chung - Hua University, Hsin - Chu, Taiwan
                <br/> <br/>
                <b>Professor Mu </b> - Song Chen  -  Department of Electrical Engineering, Da - Yeh University, Changhua, Taiwan, R.O.C.
                <br/> <br/>
                <b>Dr. Xin (Thomas) Chen  </b> -  Research Corporation of the University of Hawaii, Institute for Astronomy, Honolulu, Hawaii, USA
                <br/> <br/>
                <b>Professor Steve C. Chiu  </b> -  Program Director of Electrical Engineering, College of Engineering, Idaho State University, Idaho, USA
                <br/> <br/>
                <b>Professor Mark Yul Chu  </b> -  Department of Electrical Engineering, The University of Texas Rio Grande Valley, Texas, USA
                <br/> <br/>
                <b>Dr. Jose Alfredo F. Costa  </b> -  Member of IEEE Computational Intelligence Society; President, SBIC  -  Brazilian Computational Intelligence Society; Associate Professor, Department of Electrical Engineering, UFRN  -  Universidade Federal do Rio Grande do Norte (Federal University  -  UFRN), Brazil
                <br/> <br/>
                <b>Dr. Arianna D'Ulizia  </b> -  Institute of Research on Population and Social Policies, National Research Council of Italy (IRPPS), Rome, Italy
                <br/> <br/>
                <b>Professor Zhangisina Gulnur Davletzhanovna  </b> -  Vice - rector of the Science, Central - Asian University, Kazakhstan, Almaty, Republic of Kazakhstan; Vice President of International Academy of Informatization, Kazskhstan, Almaty, Republic of Kazakhstan
                <br/> <br/>
                <b>Dr. Wesley Deneke </b> - Computer Science Department, Western Washington University, Bellingham, Washington, USA
                <br/> <br/>
                <b>Professor Noel De Palma  </b> -  Associate Vice President for Research, Computer Science; Leader, ERODS Research, LIG Labs.; Board of Directors, OW2 Consortium; Department of Computer Science; University of Grenoble I, Grenoble, France
                <br/> <br/>
                <b>Dr. Lamia Atma Djoudi  </b> -  Synchrone Technologies, France
                <br/> <br/>
                <b>Professor Mohsen Doroodchi  </b> -  College of Computing and Informatics, University of North Carolina Charlotte, North Carolina, USA
                <br/> <br/>
                <b>Professor Levent Ertaull  </b> -  (Member: CSCI - RTCW & CSCI - RTMC); California State University, East Bay, California, USA; fellowship recipient, Lawrence Livermore National Laboratories (LLNL) in the Cyber Defenders program, USA
                <br/> <br/>
                <b>Dr. Mahmood Fazlali  </b> -  Shahaid Beheshti University, Department of Computer Science, Tehran, Iran
                <br/> <br/>
                <b>Boyuan Feng  </b> -  University of California, Santa Barbara, California, USA
                <br/> <br/>
                <b>Professor George A. Gravvanis  </b> -  Director, Physics Laboratory & Head of Advanced Scientific Computing, Applied Math & Applications Research Group; Professor of Applied Mathematics and Numerical Computing and Department of ECE, School of Engineering, Democritus University of Thrace, Xanthi, Greece
                <br/> <br/>
                <b>Dr. Gheorghe Grigoras  </b> -  Senior Lecturer, Faculty of Electrical Engineering, "Gheorghe Asachi" Technical University of Iasi, Iasi, Romania
                <br/> <br/>
                <b>Professor Ray Hashemi  </b> -  Professor of Computer Science and Information Technology, Armstrong Atlantic State University, Savannah, Georgia, USA
                <br/> <br/>
                <b>Professor Houcine Hassan  </b> -  Department of Computer Engineering (Systems Data Processing and Computers), Universitat Politecnica de Valencia, Spain
                <br/> <br/>
                <b>Dr. Abdolreza Hatamlou  </b> -  Data Mining & Optimization Research Group, Center for AI Technology, Universiti Kebangsaan Malaysia, Malaysia; and Islamic Azad University, Khoy Branch, Iran
                <br/> <br/>
                <b>Dr. Bing He  </b> -  Cisco System Inc., San Jose, California, USA
                <br/> <br/>
                <b>Professor Henry Hexmoor  </b> -  IEEE Senior member; Department of Computer Science, Southern Illinois University at Carbondale, Carbondale, Illinois, USA
                <br/> <br/>
                <b>Dr. Gahangir Hossain  </b> -  Assistant Professor, Electrical Engineering & Computer Science, Texas A&M University - Kingsville, Kingsville, Texas, USA
                <br/> <br/>
                <b>Dr. Guofeng Hou  </b> -  AQR Capital Management, New York, USA
                <br/> <br/>
                <b>Professor Ren </b> - Junn Hwang  -  Department of Computer Science and Information Engineering, Tamkang University, Taiwan, R.O.C.
                <br/> <br/>
                <b>Dr. Naseem Ibrahim  </b> -  Computer Science and Software Engineering Department, Penn State Erie, The Behrend College, Erie, Pennsylvania, USA
                <br/> <br/>
                <b>Dr. Rabia Jafri  </b> -  Department of Information Technology, King Saud University, Riyadh, Saudi Arabia
                <br/> <br/>
                <b>Professor Shahram Javadi  </b> -  Distinguished Professor and Director General of Research, Department of Electrical Engineering, Azad University, Central Tehran Branch, Iran; Visiting Professor, University of Wisconsin - Milwaukee, USA
                <br/> <br/>
                <b>Professor Young </b> - Sik Jeong  -  Department of Multimedia Engineering, Dongguk University, Republic of Korea
                <br/> <br/>
                <b>Professor Aleksandr Katkow  </b> -  Member of PTI (Polish Information Processing Society); University of Computer Sciences and Skills, Faculty of Information Technology and Management, Lodz, Poland
                <br/> <br/>
                <b>Professor Byung </b> - Gyu Kim  -  Multimedia Processing Communications Lab.(MPCL), Department of Computer Science and Engineering, College of Engineering, SunMoon University, Republic of Korea
                <br/> <br/>
                <b>Professor Tai </b> - hoon Kim  -  School of Information and Computing Science, University of Tasmania, Australia
                <br/> <br/>
                <b>Professor Sang </b> - Wook Kim  -  Hanyang University, Republic of Korea
                <br/> <br/>
                <b>Professor Dattatraya Vishnu Kodavade  </b> -  Head, Department of Computer Science and Engineering, D.K.T.E Society's Textile & Engineering Institute, India
                <br/> <br/>
                <b>Dr. Elena B. Kozerenko  </b> -  Institute of Informatics Problems of the Russian Academy of Sciences, Moscow, Russia
                <br/> <br/>
                <b>Professor Dr. Guoming Lai  </b> -  Computer Science and Technology, Sun Yat - Sen University, Guangzhou, P. R. China
                <br/> <br/>
                <b>Professor Hyo Jong Lee  </b> -  Director, Center for Advanced Image and Information Technology, Division of Computer Science and Engineering, Chonbuk National University, Republic of Korea
                <br/> <br/>
                <b>Dr. Bo Liu  </b> -  NEC Labs China, Beijing, P. R. China
                <br/> <br/>
                <b>Dr. Eleanor Lockley  </b> -  The Cultural Communication and Computing Research Institute (C3RI), Sheffield Hallam University, Sheffield, United Kingdom
                <br/> <br/>
                <b>Dr. Jianbing Ma  </b> -  School of Design, Engineering and Computing, Bournemouth University, United Kingdom
                <br/> <br/>
                <b>Professor Julius Marpaung  </b> -  Electrical Engineering Department; PI of HiPAC; University of Texas Pan American, Texas, USA
                <br/> <br/>
                <b>Dr. Andrew Marsh  </b> -  CEO, HoIP Telecom Ltd (Healthcare over Internet Protocol), United Kingdom; Secretary General of World Academy of BioMedical Sciences and Technologies (WABT) a UNESCO NGO, The United Nations
                <br/> <br/>
                <b>Dr. Juan Martinez  </b> -  Director, Acantelys Research Group and Coordinator of the Computer Engineering Department, Universidad Gran Mariscal de Ayacucho, Venezuela
                <br/> <br/>
                <b>Dr. Praveen Meduri  </b> -  Assistant Professor; Technical Liaison to Cadence Design Systems Inc. and Electrical and Electronic Engineering, California State University, Sacramento, California, USA
                <br/> <br/>
                <b>Dr. Ali Mostafaeipour  </b> -  Department of Civil and Environmental Engineering, California State University, Fullerton, California, USA
                <br/> <br/>
                <b>Dr. Houssem Eddine Nouri  </b> -  Informatics Applied in Management, Institut Superieur de Gestion de Tunis, University of Tunis, Tunisia
                <br/> <br/>
                <b>Michael B. O'Hara  </b> -  CEO, KB Computing, LLC, USA; Certified Information System Security Professional (CISSP); Certified Cybersecurity Architect (CCSA); Certified HIPAA Professional (CHP); Certified Security Compliance Specialist (CSCS)
                <br/> <br/>
                <b>Professor Dr., Eng. Robert Ehimen Okonigene  </b> -  Department of Electrical & Electronics Engineering, Faculty of Engineering and Technology, Ambrose Alli University, Edo State, Nigeria
                <br/> <br/>
                <b>Dr. Funminiyi Olajide  </b> -  Senior Lecturer, Department of Computer Science, School of Science and Technology, Nottingham Trent University, UK
                <br/> <br/>
                <b>Dr. Satish Penmatsa  </b> -  Computer Science, University of Maryland  -  Eastern Shore, Maryland, USA
                <br/> <br/>
                <b>Dr. Saman Parvaneh  </b> -  Connected Aging Solutions Laboratory, Philips Research North America, Cambridge, Massachusetts, USA
                <br/> <br/>
                <b>Professor R. Ponalagusamy  </b> -  Department of Mathematics, National Institute of Technology, Tiruchirappalli, India
                <br/> <br/>
                <b>Dr. Laura L. Pullum  </b> -  Senior Research Scientist; Member of IEEE, Academy of Science & Engineering, and Geological Society of America; Oak Ridge National Laboratory (ORNL), Tennessee, USA
                <br/> <br/>
                <b>Dr. Xuewei Qi  </b> -  Research Faculty & PI, Center for Environmental Research and Technology, University of California, Riverside, California, USA
                <br/> <br/>
                <b>Professor Junfeng Qu  </b> -  Department of Computer Science and Information Technology, Clayton State University, Morrow, Georgia, USA
                <br/> <br/>
                <b>Dr. Kalim Qureshi  </b> -  Mathematics and Computer Science Department, Kuwait University, Kuwait
                <br/> <br/>
                <b>Professor Shahram Rahimi  </b> -  Chair, Department of Computer Science; Member of IEEE Computational Intelligence New Standards Committee; Editor - in - Chief, Computational Intelligence Theory & Practice; Southern Illinois University, Carbondale, Illinois, USA
                <br/> <br/>
                <b>Dr. Jaime Raigoza  </b> -  Assistant Professor, Computer Science Department, California State University, Chico, California, USA
                <br/> <br/>
                <b>Dr. Arvind Ramanathan  </b> -  Research Scientist; Member of IEEE, American Chemical Society, and Biophysical Society; Oak Ridge National Laboratory (ORNL), Tennessee, USA
                <br/> <br/>
                <b>Dr. Iman M. Rezazadeh  </b> -  Center for Mind and Brain, University of California Davis, California, USA
                <br/> <br/>
                <b>Professor Om Prakash Rishi  </b> -  Department of Computer Science & Informatics, University of  Kota, Kota (Rajasthan), India
                <br/> <br/>
                <b>Professor Cristian Rodriguez Rivero  </b> -  IEEE Computational Intelligence Chapter Chair 2013, Aeronautical University Institute (IUA), Universidad Nacional de Cordoba, Argentina
                <br/> <br/>
                <b>Professor Seyed Roosta  </b> -  Chair, Mathematics & Computer Science Department, Albany State University, Albany, Georgia, USA
                <br/> <br/>
                <b>Dr. K. Martin Sagayam  </b> -  Assistant Professor; Electrical and Computer Engineering, Karunya Institute of Technology and Sciences, Coimbatore, India
                <br/> <br/>
                <b>Professor Dr. P. Sanjeevikumar  </b> -  (UniBo, Italy), MIUR Research Fellow (Govt. of Italy); Electrical & Electronics Engineering National Institute of Technology (NIT - Puducherry), Arignar Anna Govt. Arts College Campus, Nehru nagar, India
                <br/> <br/>
                <b>Dr. Benaoumeur Senouci  </b> -  Embedded Systems Department, LACSC Laboratory -  Central Electronic Engineering School, ECE, Paris, France
                <br/> <br/>
                <b>Dr. Zhefu Shi  </b> -  Microsoft Corporation, Redmond, Washington, USA
                <br/> <br/>
                <b>Professor Emeritus Jawed Siddiqi  </b> -  President, National Council of University Professors; Chair of The British Computer Society Formal Aspects of Computing Science; Sheffield Hallam University, United Kingdom
                <br/> <br/>
                <b>Dr. Akash Singh  </b> -  IEEE Senior Member, Member of AAAS, AAAI, AAACR, Fellow of British Computer Society (BCS), BCS CSci; IBM Corporation, Sacramento, California, USA
                <br/> <br/>
                <b>Professor Anthony Skjellum, Professor of Computer Science and Software Engineering and Charles D. McCrary Eminent Scholar Endowed Chair, Director of the Charles D. McCrary Institute, Samuel Ginn College of Engineering, Auburn University, Alabama, USA </b> - 
                <br/> <br/>
                <b>Dr. Omer Muhammet Soysal  </b> -  Southeastern Louisiana University, Hammond, Louisiana, USA
                <br/> <br/>
                <b>Professor Emeritus Helman Stern  </b> -  Department of Industrial Engineering and Management, Intelligent Systems Program, Ben Gurion University of the Negev, Israel
                <br/> <br/>
                <b>Professor Jonathan Z. Sun  </b> -  School of Computing, University of Southern Mississippi, Hattiesburg, Mississippi, USA
                <br/> <br/>
                <b>Professor Rahman Tashakkori  </b> -  Lowe's Distinguished Professor of Computer Science; Director, NSF Supported Appalachian Undergraduate Academy of Science; Director, S - STEM NSF Supported Scholarship Program; Director, NSF STEP; Program Director, NSF RET; Department of Computer Science, Appalachian State University, Boone, North Carolina, USA
                <br/> <br/>
                <b>Dr. Predrag Tosic  </b> -  Microsoft, Washington, USA
                <br/> <br/>
                <b>Professor Quoc </b> - Nam Tran  -  Chair, Department of Computer Science, Southeastern Louisiana University, Louisiana, USA
                <br/> <br/>
                <b>Professor Jesus Vigo </b> - Aguiar  -  Chair, Department of Applied Mathematics, University of Salamanca, Spain
                <br/> <br/>
                <b>Professor Patrick Wang  </b> -  Fellow of IAPR, ISIBM, WASE; Zijiang Chair Professor, ECNU, Shanghai and NTUST, Taipei, Taiwan; Founding President and CEO, Wang Teknowlogy Lab.; Northeastern University, Boston, Massachusetts, USA
                <br/> <br/>
                <b>Dr. Weiqiang Wang  </b> -  Senior Scientist, Opera Solutions LLC (a Big Data company), San Diego, California, USA
                <br/> <br/>
                <b>Dr. Yin Wang  </b> -  Department of Mathematics and Computer Science, Lawrence Technological University, Southfield, Michigan, USA
                <br/> <br/>
                <b>Professor Alicia Nicki Washington  </b> -  Member of National Society of Black Engineers, Society of Women Engineers, ACM, IEEE; Department of Computer Science and Quantitative Methods, Winthrop University, South Carolina, USA
                <br/> <br/>
                <b>Dr. Wei Wei  </b> -  School of Computer Science and Engineering, Xi'an University of Technology, P. R. China
                <br/> <br/>
                <b>Professor Yong Wei  </b> -  Department of Computer Science, University of North Georgia, Dahlonega, Georgia, USA
                <br/> <br/>
                <b>Dr. Santoso Wibowo  </b> -  Co - editor: Int'l Journal on Data Mining & Intelligent Information Technology Applications & Journal of Computer Engineering and Science; Senior Lecturer, School of Engineering and Technology, Higher Education Division, Central Queensland University, Melbourne, VIC, Australia
                <br/> <br/>
                <b>Dr. Alexander Woehrer  </b> -  Program Manager ICT, Vienna Science and Technology Fund, Schlickgasse, Vienna, Austria
                <br/> <br/>
                <b>Professor Dr. Bernd E. Wolfinger  </b> -  Telecommunications and Computer Networks Division, Computer Science Department, University of Hamburg, Germany
                <br/> <br/>
                <b>Jay Xiong  </b> -  President, NSEsoftware, LLC., USA
                <br/> <br/>
                <b>Professor Hyun Yoe  </b> -  Director of Agrofood IT Research Center and Vice President of Korea Association of ICT Convergence in the Agriculture and Food Business (KAICAF); Director of Agriculture IT Convergence Support Center (AITCSC); Department of of Information and Communication Engineering, Sunchon National University, Suncheon, Republic of Korea
                <br/> <br/>
                <b>Professor Jane You  </b> -  Associate Head, Department of Computing, The Hong Kong Polytechnic University, Kowloon, Hong Kong
                <br/> <br/>
                <b>Dr. Ismail Yusuf  </b> -  Lamintang Education and Training (LET) Center, Indonesia
                <br/> <br/>
                <b>Professor Wei Zhong  </b> -  Fellow of ISIBM; IEEE Senior member; Mathematics and Computer Science, University of South Carolina Upstate, South Carolina, USA
                </div>
        
            </div>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Org;