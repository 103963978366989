import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Contact extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Contact</h4>
            <div className="par">
            Questions and inquiries should be sent to: <br/> <br/>
            <b>AIR-RES 2025 Conference Secretariat</b>   <br/>
            <b>Email:</b> cs@american-cse.org
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Contact;