import React, { Component } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faMapMarkerAlt, faAt, faQuoteLeft, faQuoteRight, faUniversity, faClock, faTrophy,
          faGraduationCap, faLaptop, faCalendarAlt, faAtom, faCode, faChalkboardTeacher,
          faIdCard, faFileAlt, faCodeBranch, faBars, faSearch, faCalendarPlus, 
          faTimes, faTrashAlt,  faEdit, faNotesMedical, faCheckDouble, faHandshake, faWalking, faHouseUser} from '@fortawesome/free-solid-svg-icons'
import Header from './common/Header';
import Foot from './home/footer/Foot';
import {connect} from 'react-redux';
import {resizeAction, Spinner_Action} from '../actions';
// import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';
import { Sidenav, Nav, Icon, Dropdown } from 'rsuite';
import { push } from 'react-router-redux'
import Spinner from './common/Spinner';

library.add(fab, faMapMarkerAlt, faAt, faQuoteLeft, faQuoteRight, faUniversity, faClock, faTrophy, faGraduationCap,
              faLaptop, faCalendarAlt, faAtom, faCode, faChalkboardTeacher, faIdCard, faFileAlt, faCheckDouble,
              faCodeBranch, faBars, faSearch, faCalendarPlus,  faTimes, faTrashAlt,  faEdit, faNotesMedical, faHandshake, faWalking,
              faHouseUser)

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected_key: this.props.location.pathname,
      open_keys: [],
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }
  
    componentDidMount() {
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions);
    }
    
    componentWillUnmount() {
      window.removeEventListener('resize', this.updateWindowDimensions);
    }
    
    updateWindowDimensions() {
      this.props.resizeAction({width: window.innerWidth, height: window.innerHeight, expanded: window.innerWidth >= 700? true:false })
    }

  componentDidUpdate = (prevProp) => {
    if(this.props.location.pathname != prevProp.location.pathname){
      this.setState({
        selected_key: this.props.location.pathname
      })

      if(this.props.location.pathname.includes("research_tracks")){
        this.setState({
          open_keys: ['/air-res2025/research_tracks']
        })
      }else{
        this.setState({
          open_keys: []
        })
      }
    }
  }

  sideNav_select = (key) => {
    // if(key == "photos") {
    //   window.open("https://photos.ucmss.com/", "_blank")
    // }else{
      this.setState({
        selected_key: key
      })
      this.props.push(key)
    // }
  }

  render() {
    return (
      <div>
        <div>
          {this.props.Spinner_ActionReducer.show? <Spinner />: ''}
        </div>
        <div className="r_container">
        <Header />
        <div className="side_n_content">
          {this.props.resizeActionReducer.expanded &&
          <div className="nav_div">
          <Sidenav openKeys={this.state.open_keys} activeKey={this.state.selected_key} onSelect={(e)=> this.sideNav_select(e)}>
            <Sidenav.Body>
              <Nav>

                <div className="nav_logo"> 
                  <img
                    alt=""
                    src="/static/logo.png"
                  />
                </div>

                <Nav.Item eventKey="/air-res2025/">
                Home
                </Nav.Item>


                <Nav.Item eventKey="/air-res2025/topics">
                Scope & List of Topics
                </Nav.Item>

                <Dropdown eventKey="/air-res2025/research_tracks" title="Topics" onClick={()=>{
                  let a = this.state.open_keys.length == 1? [] : ['/air-res2025/research_tracks']
                  this.setState({
                    open_keys: a
                  })
                }}>
                  <Dropdown.Item eventKey="/air-res2025/topics/research">Research</Dropdown.Item>
                  <Dropdown.Item eventKey="/air-res2025/topics/ethics">Ethics</Dropdown.Item>
                  <Dropdown.Item eventKey="/air-res2025/topics/society">Society</Dropdown.Item>
                  <Dropdown.Item eventKey="/air-res2025/topics/data">Data</Dropdown.Item>
                </Dropdown>

                {/* <Nav.Item eventKey="4">
                Proceedings & Indexation
                </Nav.Item> */}
                <Nav.Item eventKey="/air-res2025/brochure">
                Brochure
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/schedules">
                Program/Schedules
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/keynotes">
                Keynotes/Invited Lectures
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/publisher">
                Publisher
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/paper_submission">
                Paper Submission
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/paper_review_pocess">
                Paper Review Process
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/paper_categories">
                Paper Categories
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/presentation_modes">
                Presentation Modes
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/demography_history">
                Demography History
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/committees">
                Conference Organization
                </Nav.Item>
                {/* <Nav.Item eventKey="11">
                Keynote Lectures
                </Nav.Item> */}
                <Nav.Item eventKey="/air-res2025/registration">
                Registration
                </Nav.Item>
                {/* <Nav.Item eventKey="13">
                  Schedules
                </Nav.Item> */}
                <Nav.Item eventKey="/air-res2025/venue">
                Venue
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/authors">
                Authors of Accepted Papers
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/deadlines">
                  Important Dates
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/contact">
                  Contact
                </Nav.Item>
                <Nav.Item eventKey="/air-res2025/career_fair">
                  Career, Job, & Education Fair
                </Nav.Item>
                {/* <Dropdown.Item divider /> */}
                <Nav.Item eventKey="/air-res2025/gallery">
                  Photo Gallery
                </Nav.Item>

              </Nav>
            </Sidenav.Body>
          </Sidenav>
            </div>
            }
          <div className="content_div">
            {this.props.children}
          </div>
        </div>
        <Foot />
      </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    resizeActionReducer: state.resizeActionReducer,
    Spinner_ActionReducer: state.Spinner_ActionReducer
  }
}

const mapDispatchToProps = () => {
  return {
    push,
    resizeAction,
    Spinner_Action
  }
}
export default connect(mapStateToProps, mapDispatchToProps())(App);
