import React, { Component } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Nav, Navbar,NavDropdown, Badge, Spinner  } from 'react-bootstrap';
import { Link, browserHistory } from 'react-router'

import {connect} from 'react-redux';
import {resizeAction} from '../../actions';
import { push } from 'react-router-redux'
import { Grid, Row, Col, Icon } from 'rsuite';

class Header extends Component {
    
    componentWillMount(){
        
    }

    render(){
        return (
            <header>
                <div className="header-area">
                    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark" sticky="top">
                    <Navbar.Brand href="">
                        <img
                            alt=""
                            src="/static/logo2.png"
                            width="30"
                            height="30"
                            className="d-inline-block align-top logo_pic"
                        />{' '}
                        AIR-RES 2025
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="mr-auto">
                        

                        </Nav>



                        <Nav>
                        
                        </Nav>
                        </Navbar.Collapse>

                        {this.props.resizeActionReducer.width <= 700 &&
                        <Icon style={{color: "#FFF", cursor: "pointer"}} icon='bars' size="2x" onClick={()=> {
                            this.props.resizeAction({width: window.innerWidth, height: window.innerHeight, expanded: this.props.resizeActionReducer.expanded? false:true })
                        }} />
                        }
                    </Navbar>
                </div>
            </header>
        )
    }
}
const mapStateToProps = state => {
    return {
      resizeActionReducer: state.resizeActionReducer
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      resizeAction
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps())(Header);