import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Demo extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Demography History (Ref: Conferences organized by American Council on Science and Education)</h4>
            <div className="par">
            <h6>Based on 1995 through 2024 participation data:</h6>
            In the past, about 45% of the attendees have been from outside USA; from 86 nations. 56% of registrants have been from academia, 32% from industry; 11% from government agencies and research labs; and 1% unknown. The conference has had participants, speakers, authors affiliated with over 572 different institutions and universities; including many from the top 40 ranked institutions and universities. A very small subset of the affiliations of participants in the recent past appears below:
            <br /><br />
            <b>- MAJOR IT CORPORATIONS, INCLUDING:</b> <br />
            Huawei Technologies (China); Apple Inc. (USA); Tesla, Inc. (USA); Microsoft Corporation (USA); Alphabet Inc. (Google - USA); Meta Platforms (Facebook - USA); Tencent Holdings (IT - China); IBM (International Business Machines - USA, India, UK); Samsung Electronics (South Korea); Oracle Corporation (USA); Intel Corporation (USA); SAP SE (Germany); Amazon (USA); Salesforce (USA); Alibaba Group (China); Dell Technologies (USA); HP Inc. (Hewlett-Packard - USA); ByteDance (TikTok and Douyin - China); Yahoo (USA); Ebay (USA); NTT (Japan); BTS Software Solutions (USA); NetApp, Inc. (Data Storage - USA); METRON (France); Wipro Technologies (India); VNIIEF (Russia); Synchrone Technologies (France); Infosys (India); Cisco Systems (USA); NVIDIA (USA); Bosch Global Software Technologies (Germany); Tata Consultancy Services (USA); SAP Software (Germany); Capgemini (France); Cognizant Technology Solutions (USA); Adobe Inc. (USA); Foxconn (Taiwan); AT&T (USA); BCL Technologies (USA); Huawei Technologies Co., Ltd. (China); Sony (Japan); Panasonic (Japan); Lenovo (China); LG Electronics (South Korea); Naver Corp (South Korea); Baidu (China); China Mobile (China); and others.
            <br /> <br />
            <b>- MAJOR CORPORATIONS (MULTI-SECTORS), INCLUDING:</b> <br />
            JPMorgan Chase & Co. (USA); Saudi Aramco (Saudi Arabia); Raytheon (RTX Corporation - USA); Visa Inc. (USA); Secured Smart Electric Vehicle (South Korea); Johnson & Johnson (USA); Toyota Motor Corporation (Japan); Procter & Gamble (P&G - USA); Roche Holding AG (Pharmaceuticals and Biotechnology - Switzerland); Walmart (USA); Chevron Corporation (USA); Hyundai Motor Company (South Korea); Exxon Mobil (USA); PetroChina (China); GlaxoSmithKline (UK); CERENA Petroleum Group (Portugal); Aerospace Electronic Technology (China); Max Planck (Germany); Bank of China (China); ENGIE (France); Forschungszentrum Juelich (Germany); China National Petroleum; BASF (Germany); Bayer (Germany); Johnson & Johnson (USA); HSBC (UK); ICBC (Industrial and Commercial Bank of China - China); Airbus (France); Berkshire Hathaway (USA); Boeing (USA); Goldman Sachs (USA); Deutsche Bank (Germany); Sinopec Group (China); United Health (USA); Volkswagen (Germany); China Construction Bank (China); Barclays Bank (UK); BP (UK); Royal Dutch Shell (Netherlands); Daimler (Germany); China Railway Engineering Corp. (China); Cigna (USA); Trafigura (Singapore); Glencore (Switzerland); Ford (USA); AXA (France); Taiwan Semiconductor (Taiwan); Accenture (IReland); Siemens (Germany); Philips (Netherlands); Ericsson (Sweden); General Electric (USA); BAE Systems (USA); Hitachi (Japan); Fraunhofer IKTS (Germany); State Grid Corporation (China); Foxconn (Taiwan); and others.
            <br /><br />
            <b>- GOVERNMENT RESEARCH AGENCIES AND NATIONAL LABORATORIES, INCLUDING:</b> <br />
            National Science Foundation (NSF), National Institutes of Health (NIH), US Air Force (multiple research labs), National Security Agency (NSA), Pacific Northwest National Lab (PNNL), National Aeronautics and Space Administration (NASA), Lawrence Berkeley National Lab (LLNL), Lawrence Livermore National Lab, Los Alamos National Lab (LANL), Argonne National Lab, Cold Spring Harbor Lab, US National Cancer Institute, Swedish Defence Research Agency, US National Institute of Standards and Technology (NIST), Oak Ridge National Lab, U.S. Geological Survey, US National Library of Medicine, SPAWAR Systems Center Pacific, CERN (Switzerland), China NSF, Russian Academy of Sciences, Sandia National Lab, Savannah River National Lab, US Navy, Ames Lab, Hasso Plattner Institute, US Army, Korea Internet & Security Agency, DESY (Germany), LNGS (Italy), Suez Canal Research Center, Okinawa Bureau (Japan), Canadian Medical Protective Association, Osong Medical Innovation Foundation (Korea), Royal Observatory Edinburgh (UK), Jet Propulsion Laboratory (JPL), Gran Sasso National Lab (Italy), National Institute of Informatics (Japan), Frederick National Lab for Cancer Research, Idaho National Laboratory, ...; and three Venture Capitalists and eleven Intellectual Property attorneys, and others.

            <br /><br />
            We anticipate to have attendees from about 65+ countries. To get a feeling about the conferences' atmosphere organized by American Council on Science and Education, see some delegates photos available at (over 2,000 photos):<br />
            <a href="https://www.american-cse.org/csci2024/gallery" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csci2024/gallery</a> <br />
            <a href="https://www.american-cse.org/csce2024/gallery" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/csce2024/gallery</a> <br />
            <a href="https://photos.ucmss.com/ " target="_blank" rel="noopener noreferrer">https://photos.ucmss.com/</a>

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Demo;