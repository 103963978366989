import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Schedules extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          {/* <h6 className="par_header">Book of Abstracts with Links to Presentations</h6>
            <div className="par">
            To download a copy of the Book of Abstracts with Links to Presentations <a href="/static/ci20-Book-of-abstracts-presentations-web.pdf" target="_blank" rel="noopener noreferrer">CLICK HERE</a> (current as of January 22, 2024).

            </div> */}


          <h6 className="par_header">Program/Presentation Schedules</h6>
            <div className="par">
            Will Be Made Available in April 2025
            {/* Authors and speakers were given the following presentation options:
            <br /><br />
            <b>- OPTION 1:</b> Presenting the paper on site at the conference<br />
            <b>- OPTION 2:</b> Presenting the paper remotely: using Zoom (interactive) <br />
            <b>- OPTION 3:</b> Presenting the paper remotely (non-interactive). The link to these pre-recorded presentations will later be included in the "Book of Abstracts" and will be made available on the AIR-RES 2025 website at <a href="https://www.american-cse.org/air-res2025/" target="_blank" rel="noopener noreferrer">( https://www.american-cse.org/air-res2025/  )</a> AFTER the conference.

            <br /><br />

            <div style={{fontSize: "2em"}}> <b>All dates & times that appear in the Presentation Schedules are given in Las Vegas time (i.e., USA Pacific Time Zone).</b></div>
            <br />


            To download/view a copy of the Presentation Schedules in .pdf  <a href="/static/BOOKLET-CSCI-2024-updated.pdf" target="_blank" rel="noopener noreferrer">Click Here</a> 
            <br/>
            To download/view a copy of the Presentation Schedules in .doc  <a href="/static/BOOKLET-CSCI-2024-updated.docx" target="_blank" rel="noopener noreferrer">Click Here</a>
            <br />
            To download/view a copy of the Cover for Presentation Schedules <a href="/static/Cover Schedules - AIR-RES 2025.pdf" target="_blank" rel="noopener noreferrer">Click Here</a>

            <br /> <br />
            All scheduling constraints reported by the speakers have already been incorporated into the presentation schedule.
            <br />
            The online/ZOOM sessions are clearly identified as such in the schedules (see above). The ZOOM links are already emailed to the authors of accepted papers (please check your spam folders). If you cannot find the ZOOM link, then please send an email to the following address: (in your email, you should provide the following information: Your name + your professional email address + your paper ID number + title of paper): 
            <br />
            <br />
            <b>CSCI.ZOOM@gmail.com</b>
            <br />
            <br />
            
            <br/>
            To download/view a copy of the Book of Abstracts in .pdf <a href="/static/Book-of-abstracts-updated.pdf" target="_blank" rel="noopener noreferrer">Click Here</a><br /> 
            To download/view a copy of the Cover for Book of Abstracts <a href="/static/air-res2025 - Cover Abstracts.pdf" target="_blank" rel="noopener noreferrer">Click Here</a> */}
 
            {/* To download/view a copy of the Book of Abstracts in .pdf: (Coming Soon) <br />
            To download/view a copy of the Book of Abstracts in .doc: (Coming Soon) */}

            <br/> <br/>
 
            

            {/* To prepare the congress program/schedule, the title of all papers, authors names, affiliations were extracted from the meta-data of the draft paper submissions (manuscripts submitted by the authors for evaluation). Any changes to the titles of the papers, authors names, affiliations, ... are not reflected in this program. The FINAL versions of the papers (uploaded to the publication portal) will have the correct information.

            <br/> <br/>
            The ZOOM links will be provided (by email) to the REGISTERED authors, speakers, and participants. 
            <br/><br/>
            In case of questions during the conference dates (December 11-13, 2024), send email to: <b>CSCI.ZOOM@gmail.com</b>
            <br /><br />
            <b>Note:</b>  The presentation schedules have already been finalized.
                At this late date, any changes would have to be managed in an Ad-Hoc manner.
                Authors can attend (face-to-face or online/virtual) asking the moderator
                for any changes they wish to have. The zoom links have already been
                emailed to all authors. */}

            </div>
            
            {/* <h6 className="par_header">IMPORTANT: COVID-19 Rapid Testing</h6>
            <div className="par">
            For the safety of all, it is IMPORTANT and NECESSARY that all AIR-RES 2025 on-site (physical) attendees and speakers to take the COVID-19 Rapid Test. AIR-RES 2025 has arranged a rapid testing protocol on site at the convention (at no cost to the attendees). Please schedule an appointment (all appointments will be between 06:30am and 10:30am on December 16, 2024) to take the test. In order to schedule your appointment, please use the link below - the test will take only a few minutes and the results will be provided in about 30 minutes.
            <br/> <br/>
            <a target="_blank" href="https://www.impacthealth.com/air-res2025/ ">https://www.impacthealth.com/air-res2025/</a>
            <br/>
            The above is a simple (user-friendly) system - but if you need instructions, please <a href="/static/COVID-19-rapidtest-AIR-RES2025.pdf" target="_blank" rel="noopener noreferrer">Click Here</a>
            <br/> <br/>
            In case of difficulties to use the above web site to schedule a time for your test, then please show up at the testing location on December 16, 2024 (Wednesday) between 6:30am and 10:30am. The location of COVID-19 rapid testing is very close to the conference ballrooms (only about 1 minute walk away).
            <br/>
            COVID-19 rapid testing location: EGYPTIAN BALLROOM G, Luxor hotel, 3900 Las Vegas Blvd South, Las Vegas, Nevada 89119, USA.
            </div> */}
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Schedules;