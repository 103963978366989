import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsEthics extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">ETHICS RESEARCH - ETHICAL CONCERNS IN ARTIFICIAL INTELLIGENCE</h4>
            <div className="par">
            <h6>Prologue:</h6>
              <div>
              Ethical concerns in AI encompass a range of critical issues. Bias and fairness in AI involve addressing algorithmic biases to ensure equitable decision-making. The ethical use of generative AI focuses on tackling challenges such as deepfakes, misinformation, and plagiarism while balancing creative freedom with potential societal harm. In education, AI ethics examine the impact of AI tools on student learning, creativity, and assessment fairness. Transparency and explainability are crucial for ensuring AI systems remain interpretable and understandable by humans, while accountability requires establishing legal frameworks to address responsibility for AI-driven decisions. Privacy and data protection are essential to safeguard user information in AI-powered systems. Additionally, AI in art and creativity raises questions about the ownership of AI-generated content, and the rise of AI systems mimicking or influencing human emotions brings forward ethical concerns about emotional intelligence in machines. Together, these topics highlight the need for thoughtful and comprehensive ethical approaches to AI development and deployment. The list of topics below is by no means meant to be exhaustive:

              <br /><br />
              - Ethics of AI in Education
              <br/>- Bias and Fairness in AI
              <br/>- Ethical Use of Generative AI
              <br/>- Transparency and Explainability
              <br/>- Accountability and Responsibility
              <br/>- Privacy and Data Protection
              <br/>- Ethics of AI in Art and Creativity
              <br/>- AI and Emotional Intelligence
              <br/>- Environmental Concerns

              </div>




            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsEthics;