import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';


class Home extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">International Conference on the AI Revolution: Research, Ethics, and Society (AIR-RES 2025)</h4>
            <div className="par">
            We are excited to announce the "International Conference on the AI Revolution: Research, Ethics, and Society (AIR-RES)", bringing together leading experts, researchers, and innovators from around the world to explore the latest advancements and future directions in AI. This international conference will cover a broad spectrum of topics, including machine learning, natural language processing, robotics, autonomous systems, and AI ethics, showcasing cutting-edge research, practical applications, and discussions on the societal impacts of AI. As artificial intelligence continues to revolutionize industries such as healthcare, finance, education, and more, this event offers a unique opportunity for attendees to engage in thought-provoking discussions, discover innovative solutions, and collaborate on the challenges and opportunities shaping the future of AI. Join us for this exciting gathering of minds, as we explore how AI is transforming the world and what lies ahead in this rapidly evolving field. This conference is expected to be among the top five largest annual gatherings of researchers in the field of Artificial Intelligence.
             <br />
              <br />
              You are invited to submit a paper for consideration. All accepted papers will be published by Springer Nature in the proceedings of The 2025 "International Conference on the AI Revolution: Research, Ethics, and Society (AIR-RES'25)" and indexed in major Science Citation Indexation databases.
              <br /><br />
              AIR-RES'25 International Conference is composed of a number of Research Tracks, sessions, workshops, posters and panel discussions; all will be held simultaneously, same location and dates.
              <br /><br />
              For list of Topics, refer to: <a href="https://www.american-cse.org/air-res2025/topics" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/topics</a>   <br />
              For submission of papers, refer to: <a href="https://www.american-cse.org/air-res2025/paper_submission" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/paper_submission</a> <br />
              For deadlines, refer to: <a href="https://www.american-cse.org/air-res2025/deadlines" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/deadlines</a> <br />

              
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Home;