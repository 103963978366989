import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';
import { Button } from 'react-bootstrap';
import { push, replace } from 'react-router-redux'
import {connect} from 'react-redux';
import axios from "axios"
import conf from '../../_Conf'

class Visa_done extends Component {
  componentWillMount = () =>{
    try {
      let st = this.props.location.state.form
    } catch {
        this.props.replace("/air-res2025")
    }
  }
  
  
  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
          <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Invitation Letter Request - Success!</h4>
            <div className="par">            
                <div style={{
                    margin: "2rem auto",
                    fontSize: "30px",
                    color: "green"
                }}>SUCCESS!</div>
                This is an automated message to confirm that your invitation letter request has been submitted.
                We will respond to your request as soon as your information is verified.
                <br/>

                <br/>
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
    }
  }
  
  const mapDispatchToProps = () => {
    return {
      push,
      replace
    }
  }
  export default connect(mapStateToProps, mapDispatchToProps())(Visa_done);