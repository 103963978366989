import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class SympsSociety extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">SOCIETY - SOCIETAL AND PHILOSOPHICAL IMPACTS OF ARTIFICIAL INTELLIGENCE</h4>
            <div className="par">
            <h6>Prologue:</h6>
              <div>
              The societal and philosophical impacts of AI raise important ethical questions across various domains. AI and human rights focus on protecting fundamental rights in the deployment of AI technologies. Labor and economic impacts involve examining AI's role in job displacement, wealth inequality, and the implications of using AI for productivity at the cost of human labor. The future of human-AI collaboration is also a key concern, as AI continues to reshape workforce dynamics. Ethical issues surrounding autonomous systems, including autonomous weapons, drones, and self-driving cars, highlight the need for responsible development. Long-term risks associated with superintelligent AI bring existential concerns, requiring a balance between immediate ethical issues and long-term considerations. Lastly, global AI governance emphasizes the importance of creating international standards and regulations to ensure ethical AI use worldwide. The list of topics below is by no means meant to be exhaustive:

              <br /><br />
              - AI and Human Rights
              <br />- Labor and Economic Impacts
              <br />- AI and Autonomous Systems
              <br />- Long-Term Risks of Superintelligent AI
              <br />- Global AI Governance


              </div>              

            </div>
            
          </div>
          

        </div>
      </div>
    );
  }
}

export default SympsSociety;