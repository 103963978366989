import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class PaperS extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Submission of Papers</h4>
            <div className="par">
            To submit your paper for Evaluation, upload to: <a href="https://american-cse.org/air-res-drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/air-res-drafts/</a> <br /> <br />

            All accepted papers will be published by Springer Nature (<a href="https://www.springernature.com" target="_blank" rel="noopener noreferrer">https://www.springernature.com</a>) in the proceedings of The 2025 "International Conference on the AI Revolution: Research, Ethics, and Society (AIR-RES'25)". 
            <br /> <br />

            PUBLISHER:  
            SPRINGER NATURE
            INDEXATION: Scopus; DBLP, EI Engineering Index (Compendex, Inspec databases); Springer Link; Google Scholar; Conference Proceedings Citation Index (CPCI), part of Clarivate Analytics' Web of Science; ACM Digital Library; IO-Port; MathSciNet; Zentralblatt MATH, and others. Only about 5% of all journals and conference proceedings have the same high level of scientific indexing as publications of conferences organized by American Council on Science and Education.

            Each paper will be peer-reviewed by two experts in the field for originality, significance, clarity, impact, and soundness. In cases of contradictory recommendations, a member of the conference program committee would be charged to make the final decision; often, this would involve seeking help from additional referees. Papers whose authors include a member of the conference program committee will be evaluated using the double-blinded review process. (Essay/philosophical papers will not be refereed but may be considered for discussion/panels).

            </div>

            <h6 className="par_header">INSTRUCTIONS FOR SUBMISSION OF PAPERS FOR EVALUATION:</h6>
            <div className="par">
            For the submission of draft papers (ie, papers submitted for evaluation), any reasonable formatting/typesetting is acceptable. Authors of accepted papers will later receive specific typesetting instructions to prepare their final versions. The final papers are expected to use the standard Springer Nature typesetting format ("US letter" standard size): <br /><br />
            WORD template:  <a href="https://www.springer.com/gp/authors-editors/conference-proceedings/editors/word-template/19338734" target="_blank" rel="noopener noreferrer">https://www.springer.com/gp/authors-editors/conference-proceedings/editors/word-template/19338734</a> <br />
            LaTeX template: <a href="https://www.springer.com/gp/computer-science/lncs/new-latex-templates-available/15634678" target="_blank" rel="noopener noreferrer">https://www.springer.com/gp/computer-science/lncs/new-latex-templates-available/15634678</a> <br /><br />

            All submissions must be original and must not have been previously published or currently under consideration for publication elsewhere. <br /><br />

            For categories of papers and page limitations, refer to: <a href="https://www.american-cse.org/air-res2025/paper_categories" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/paper_categories</a>
          <br /><br />
          Prospective authors are invited to submit their papers by uploading them to the evaluation web site at: <a href="https://american-cse.org/air-res-drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/air-res-drafts/</a> <br />


          Submissions must be uploaded by the due date (See Important Dates: <a href="https://www.american-cse.org/air-res2025/deadlines" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/deadlines</a>) and must be in either MS WORD doc or pdf formats. For more information regarding Paper Categories Click Here https://www.american-cse.org/air-res2025/paper_categories 

                <br/><br/>
                The first page of the paper should include the followings: <br />
                - Title of the paper <br/>
                - Name, affiliation, City, Country, and email address of each author (identify the name of the Contact Author). For Science Citation Indexation (SCI) inclusion processes, we prefer that the Contact Authors to provide email addresses that can be traced (ie, SCI requires that the Contact Authors be positively identified.) <br/>
                - Abstract (between 100 and 120 words)) <br/>
                - A maximum of 6 topical keywords (Indexed Terms) that would best represent the work described in the paper (It would be more helpful to provide topical keywords that do not appear in the Title of the paper, if possible.) <br/>
                - After the topical keywords, Write the type of the submission as "Regular Research Paper", "Short Research Paper", or "Extended Abstract/Poster Research Paper". <br />
                - The actual text of the paper can start from the first page (space permitting). <br /><br />


<br />
To submit your paper for evaluation, please upload the paper to: <a href="https://american-cse.org/air-res-drafts/" target="_blank" rel="noopener noreferrer">https://american-cse.org/air-res-drafts/</a> <br />

            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default PaperS;