import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Brochure extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">
          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Brochure</h4>
            <div className="par">
                <div style={{textAlign: 'center'}}>
                    <img src="/static/brochure-airres25.png" alt="Brochure" style={{maxWidth: '80%'}}/>
                </div>
            </div>
            
            
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Brochure;