import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';

class Jobs extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
          <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h4 className="par_header">Career, Job, & Education Fair</h4>
            <div className="par">
            The 2025 International Conference on the AI Revolution: Research, Ethics, and Society (AIR-RES 2025) plans to
host a &quot;Job &amp; Education Fair&quot; to be held concurrently with the conference (April 14-16, 2025). This annual strategic
event provides the conference attendees and participants with opportunities to connect with employers and educators

and learn more about career options. Corporations and Universities can exhibit their products, services, and degree
programs during the event.
            <br/><br/>
            Interested parties should contact cs@american-cse.org
            </div>
            

          </div>
          

        </div>
      </div>
    );
  }
}

export default Jobs;