import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';
import { Link } from 'react-router';


class Reg extends Component {

  render() {
    return (
      <div className="r_container">
        <div className="home">


          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
            <h4 className="par_header">Registration</h4>

            <h6 className="par_header">Fees</h6>
            <div className="par">
            The registration fees are set at the Academia Rate as shown below:
            <br/><br/>
            <b>Author Registration:</b> 
            <br/><br/>
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={8}>
                Full Time Student: <br/>
                Non-Student:   
                </Col>
                <Col xs={16}>
                $575.00 * <br/>
                $675.00 *
                </Col>
              </Row>
            </Grid>

            <br/>
            * Authors with more than one accepted paper must pay an <b>"Extra Paper/Publication Charge"</b> of $495.00 for each extra paper. For example, the fee for one author attending with two accepted papers would be: 675 + 495 (or 575 + 495 in the case of a full-time student).
            <br/><br/>

            <p style={{fontSize: "0.75rem"}}>
            * This year's conference will be a hybrid event that combines a "live" in-person event with a "virtual" online component 
            (See: <a href="https://www.american-cse.org/air-res2025/presentation_modes" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/presentation_modes</a>). 
            Based on our survey, we anticipate that most speakers will be physically attending the conference. Irrespective of mode of attendance (physical/on-site, online/virtual, ...), the registration fees are set as shown here. There will be no reduction in fees for authors/speakers who do not physically attend the conference. This is because the fees cover the publication cost, pre-screening, limited English editing, data collection and preparation for science indexation processes, meeting management subcontractors, web servers, hourly paid staff, conference infrastructure, insurance, and other related costs.
            </p>

            <br />

            <b>General Attendee/Non-author Participants:</b> <br/><br/>
            <Grid fluid>
              <Row className="show-grid">
                <Col xs={8}>
                Full Time Student: <br/>
                Non-Student:   
                </Col>
                <Col xs={16}>
                $575.00 <br/>
                $675.00
                </Col>
              </Row>
            </Grid>

            </div>

            

            <h6 className="par_header">Payment Methods</h6>
            <div className="par">
              <h6>Online Payment</h6>
              <br/>
              <div>
                <img src="/static/cards.png" width="250px"/>
              </div>
              <div className="card_link">
                <Link to="/air-res2025/registration_start"> <b>Click To Start</b> </Link>
              </div>

              <hr/>

              <div>
              <h6>Payment by Check:</h6>
              Those who are registering by postal mail are to send their payment (registration fee) to: <br/><br/>

              CSREA (AIR-RES 2025) <br/>
              1060 Long Creek Drive, <br/>
              Watkinsville, Georgia 30677 <br/>
              USA <br/>
              <br/>
              Check payments should be in US Dollars and made payable to “CSREA”. <br/> <br/>
              Enclosed in the envelope should be a letter with the following information: <br/>
                - Check amount enclosed <br/>
                - Name of person registering <br/>
                - His/Her title (Prof./Dr./Mr./Ms./...) <br/>
                - Affiliation and Country <br/>
                - Email address <br/>
                - Accepted Paper ID number(s) (if any) <br/>
                - Title(s) of accepted paper(s) (if any) <br/>

                <hr/>
                <h6>Bank Wire Transfer:</h6>
                Please contact cs@american-cse.org for the wire instructions (your email
                should include the accepted paper ID number and title of accepted paper.)
                <br/><br/>
              </div>

            </div>
            
            <h6 className="par_header">Terms  & Conditions</h6>
            <div className="par">
            <b>- Refund Policy:</b> Paid NON-AUTHOR registrants who cannot participate and do not arrange a substitute, are entitled to a partial refund of $200.00 if a request is received in writing on or before March 30, 2025. Registrants are liable for their full fees after that date (i.e., NO Refund will be made). There will be NO REFUNDS to authors of papers (since authors' fees are used to cover the publication, preparation of indexation meta-data, organization, infrastructure, ... costs of authors' own papers.)"
            <br/><br/>
            <b>- The conference cannot guarantee the issuance of USA visa; authors whose USA visa is not issued in a timely manner can present their papers online - there will be no refund of registration fee.</b>
            <br /><br />
            <b>- For those who are physically attending the conference: </b>
            Registration Fee will include publication of their papers, printed Conference Program (a conference package); breakfasts, conference reception dinner; refreshment breaks (coffee/tea, etc.); access to all sessions, panel discussions, keynotes/Invited lectures; miscellaneous conference materials.
              <br/><br/>
            <b>- For those who are NOT physically attending the conference (ie, virtual participation): </b>
            Registration Fee will include publication of accepted paper(s); access to Conference Program; access to sessions; dissemination of their presentation and published paper (in case of speakers, authors), ...
            <br/><br/>
            <b>- Authors of papers</b> must register and must upload their correctly typeset papers to the publication web site by the announced deadlines, if they wish for their papers to be published.
            <br/><br/>
            <b>- All Payments</b> must be in U.S. Dollars. All checks from banks outside the United States should be cashable at a branch of that bank in the United States or at any US bank.
            <br/><br/>
            <b>- Authors with more than one paper</b> must pay an "Extra Publication Charge" of $495.00 for each additional paper.
            {/* <br/><br/>
            <b>- Student rate</b> attendees must have a letter from their Department Head/Chair that states that the attendee is a full time student. This letter is required to pick up the registration materials. */}
              
            </div>
          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Reg;