import React, { Component } from 'react';
import {connect} from 'react-redux';
import { Alert  } from 'react-bootstrap';
import axios from "axios"
import conf from '../../../_Conf'
import ReactGA from 'react-ga';
import {alertMess} from '../../../actions';

function getYear() {
  return new Date().getFullYear();
}

class Foot extends Component {

  state = {
    counter: 0
  }

  componentDidUpdate = (prev) => {
    
  }

  componentWillMount = () => {
    ReactGA.initialize('UA-132872836-1');
    ReactGA.pageview('/');
    ReactGA.event({
      category: 'CSCI',
      action: 'Website Viewed'
    });
    axios.post(conf.Production? conf.ProductionAddress + 'air-res2025/update_counter/' : 'http://127.0.0.1:5050/' + 'air-res2025/update_counter/',
    {},
    { headers: { 'Content-Type': 'application/json' } }
    ).then(response => {
        this.setState({
          counter: response.data.counter
        })        
    }
    )
  }

  componentDidUpdate = (prev) => {
    if(prev.routing.locationBeforeTransitions.pathname != this.props.routing.locationBeforeTransitions.pathname) {
      this.props.alertMess({isVis: false, message:'', error:false})
    }
    if(JSON.stringify(prev) != JSON.stringify(this.props)){
      ReactGA.initialize('UA-132872836-1');
      ReactGA.pageview('/');
      ReactGA.event({
        category: 'CSCI',
        action: 'Website Viewed'
      });
      axios.post(conf.Production? conf.ProductionAddress + 'air-res2025/update_counter/' : 'http://127.0.0.1:5050/' + 'air-res2025/update_counter/',
        {},
        { headers: { 'Content-Type': 'application/json' } }
        ).then(response => {
            this.setState({
              counter: response.data.counter
            })        
        }
      )
    }
  }

  render(){
  return (
    <div className="r_container">

      <div className="foot">

        <div id="bg7"></div>
        <div className="content-wrapper">

            {this.props.alertReducer.isVis && 
            <div className="univ_alert">
              <Alert variant={this.props.alertReducer.error ? "danger" : "success"} onClose={() => this.props.alertMess({isVis: false, message:"", error:true})} dismissible>
                <p>
                  {this.props.alertReducer.message}     
                </p>
              </Alert>
            </div>}

            <div className="footContent">
              <h5>©{getYear()} American Council on Science and Education</h5>
              <h5>ALL RIGHTS RESERVED</h5>
              <div className="foot_counter">
                <div className="c_number">
                  {this.state.counter}
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
  }
}

const mapStateToProps = state => {
  return {
      routing: state.routing,
      alertReducer: state.alertReducer,
  }
}

const mapDispatchToProps = () => {
  return {
    alertMess,
  }
}

export default connect(mapStateToProps, mapDispatchToProps())(Foot);
