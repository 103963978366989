import React, { Component } from 'react';
import { Grid, Row, Col, Icon } from 'rsuite';

class Deadlines extends Component {

    componentDidMount = () => {
        // console.log(this.props)
    }


  render() {
    return (
      <div className="r_container">
        <div className="home">

          <div className="new_banner">
            <div className="banner_content">
              <h1>AIR-RES 2025</h1>
              <h6>The 2025 International Conference on the</h6>
              <h6>AI Revolution: Research, Ethics, and Society</h6>
              <h6>April 14-16, 2025, Las Vegas, USA</h6>
            </div>

            <div className="new_banner_overlay"></div>
          </div>
          

          <div className="page_content">
          <h5 className="par_header">DEADLINES - DATES</h5>
          <h6 className="par_header">FOR AUTHORS OF PAPERS & SPEAKERS:</h6>
            <div className="par">

            - SUBMISSION OF PAPERS (for peer-review evaluation):<br />
            &emsp; <b>February 16, 2025</b> <br /> 
            &emsp; <a target="_blank" href="https://american-cse.org/air-res-drafts/">https://american-cse.org/air-res-drafts/</a> 
            <br/>            
            <br />

            - NOTIFICATION OF ACCEPTANCE/REJECTION (+/- 2 days):<br />
            &emsp; <b>February 26, 2025</b>

            <br /><br />

            - REGISTRATIONS:<br />
            &emsp; <b>March 10, 2025</b> <br />
            &emsp; <a href="https://american-cse.org/air-res2025/registration" target="_blank" rel="noopener noreferrer">https://american-cse.org/air-res2025/registration</a>

            <br /><br />
            - LOCATION AND ROOM RESERVATION: <br />
            &emsp; <b>March 19, 2025</b> <br />
            &emsp;Luxor - an MGM property, Las Vegas, USA <br />
            &emsp;<a href="https://www.american-cse.org/air-res2025/venue" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/venue</a>

            
            <br /><br />
            <b>- The 2025 International Conference on the AI Revolution: Research, Ethics, and Society (AIR-RES 2025):</b> <br />
            &emsp; <b>April 14-16, 2025</b> <br />
            &emsp; <a href="https://www.american-cse.org/air-res2025/" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/</a> <br />
            {/* &emsp; + all affiliated Research Tracks: <br />
            &emsp; <a href="https://www.american-cse.org/air-res2025/topics" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/topics</a> <br /> */}
            
            <br />
            - FINAL PAPERS:<br />
            &emsp; <b>May 16, 2025</b> <br />
            &emsp; The conference proceedings will be published after the
                         event. Authors of accepted papers will receive an email
                         with the PUBLICATION link well in advance.

            
{/* <br /><br /><br /> */}
            {/* <i>(Note: We will probably extend the deadlines as needed – however, the sooner a paper is submitted, the sooner notification can be sent.)</i> */}
            <br /><br />
            </div>

            <h6 className="par_header">FOR NON-AUTHORS & NON-SPEAKER ATTENDEES:</h6>
            <div className="par">
            
            - REGISTRATIONS:<br />
            &emsp; <b>March 15, 2025</b> <br />
            &emsp; <a href="https://american-cse.org/air-res2025/registration" target="_blank" rel="noopener noreferrer">https://american-cse.org/air-res2025/registration</a>
            <br /> &emsp; Authors of accepted papers will find the registration deadline in their notification of acceptance.
            <br /><br />
            - LOCATION AND ROOM RESERVATION: <br />
            &emsp; <b>March 19, 2025</b> <br />
            &emsp;Luxor - an MGM property, Las Vegas, USA <br />
            &emsp;<a href="https://www.american-cse.org/air-res2025/venue" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/venue</a>

            
            <br /><br />
            <b>- The 2025 International Conference on the AI Revolution: Research, Ethics, and Society (AIR-RES 2025):</b> <br />
            &emsp; <b>April 14-16, 2025</b> <br />
            &emsp; <a href="https://www.american-cse.org/air-res2025/" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/</a> <br />
            {/* &emsp; + all affiliated Research Tracks: <br />
            &emsp; <a href="https://www.american-cse.org/air-res2025/topics" target="_blank" rel="noopener noreferrer">https://www.american-cse.org/air-res2025/topics</a> <br /> */}
            

            </div>

          
          </div>
          

        </div>
      </div>
    );
  }
}

export default Deadlines;